
$theme-colors: (
    "primary": #EE5557,
    "danger": #ff4136,
    "dark": #000000
    );
    
    .rockstar {
        font-family: "RockStar" !important;
}

.rockstar-semibold {
    font-family: "RockStar semibold" !important;
}

.inter {
    font-family: 'Inter', sans-serif;
}

.wadik {
    font-family: "Wadik" !important;
}

@import "/node_modules/bootstrap/scss/bootstrap.scss";